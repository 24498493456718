import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';

let config = {
  apiKey: "AIzaSyDAW9RYVTaoE-2Qg1MiZLkZWjRJovCM9Fk",
  authDomain: "phrain-kg.firebaseapp.com",
  databaseURL: "https://phrain-kg.firebaseio.com",
  projectId: "phrain-kg",
  storageBucket: "",
  messagingSenderId: "554834153380",
  appId: "1:554834153380:web:cf1a6d6a6e47df3cbabb31"
};

firebase.initializeApp(config);
if( !firebase.apps.length ){
  firebase.initializeApp(config);
}

/*
if (process.env.NODE_ENV === 'development') {
  firebase.functions().useFunctionsEmulator('http://localhost:5001')
}
*/

const db = firebase.firestore();
const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp;
const auth = firebase.auth();

export {
  firebase,
  db,
  dbTimestamp,
  auth,
};